import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RoomIcon from '@material-ui/icons/Room';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#1a2f48',
  },
  paper: {
    textAlign: 'center',
    backgroundColor: '#1a2f48',
  },
  footer: {
    marginTop: '6rem',
    backgroundColor: '#778899',
    color: theme.palette.common.white,
    // width: '100%',
    height: '100px',
  },
}));

export default function Footer() {
  const classes = useStyle();
  return (
    <footer className={classes.footer}>
      <div className={classes.root} style={{paddingTop: "10px"}}>
        <Grid container justify='center'>
          <Grid item sm={3}>
            <Box className={classes.paper}>
              <Typography>Tampereen Hyötyauto Oy</Typography>
              <Typography>Y-tunnus: 2080825-0</Typography>
            </Box>
          </Grid>
          <Grid item sm={3}>
            <Box className={classes.paper}>
              <Typography>tampereenhyotyauto@suomi24.fi</Typography>
              <Typography>050 3588 213</Typography>
            </Box>
          </Grid>
          <Grid item sm={3}>
            <Box className={classes.paper}>
              <Typography>Teollisuustie 8</Typography>
              <Typography>33470 YLÖJÄRVI</Typography>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box className={classes.paper}>
              <IconButton href='tel:+358503588213' color='inherit'>
                <PhoneEnabledIcon />
              </IconButton>
              <IconButton
                href='mailto:tampereenhyotyauto@suomi24.fi'
                color='inherit'
              >
                <MailOutlineIcon />
              </IconButton>
              <IconButton
                color='inherit'
                href='https://goo.gl/maps/Jn1J7oErhcy'
                target='_blank'
              >
                <RoomIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}
