import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  box: {
    height: '30px',
    backgroundColor: '#1a2f48',
  },
  toolbar: {
    backgroundColor: '#1a2f48',
    paddingTop: 10,
    paddingBottom: 10,
    //paddingRight: 40,
  },
  tabContainer: {
    marginLeft: 'auto',
    [theme.breakpoints.between('lg', 'xl')]: {
      paddingRight: 10,
    },
  },
  tab: {
    marginLeft: -8,
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      color: '#2ea6b8',
    },
  },
  button: {
    color: '#fff',
    marginTop: 12,
  },
  drawer: {
    width: '80%',
    backgroundColor: '#1a2f48',
  },
  drawerIconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: '#fff',
  },
  drawerIcon: {
    height: '30px',
    width: '30px',
  },
  indicator: {
    backgroundColor: '#fff',
    height: '3px',
  },
  listClass: {
    '&:hover': {
      color: '#000',
    },
  },
  typography: {
    fontFamily: '"Exo"',
    marginLeft: 20,
  },
  test: {
    alignContent: 'center',
    alignItems: 'center',
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  valikko: {
    marginRight: 2,
  },
  img: {
    height: 90,
    [theme.breakpoints.down('lg')]: {
      height: 65,
    },
    [theme.breakpoints.down('sm')]: {
      height: 22,
    },
    [theme.breakpoints.down('xs')]: {
      height: 20,
    },
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);
  //const [openMenu, setOpenMenu] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (e, value) => {
    setValue(value);
  };

  const tabs = (
    <React.Fragment>
      <Box className={classes.box}></Box>
      <ToolBar disableGutters className={classes.toolbar}>
        <Button className={classes.button} component={Link} to='/'>
          <img
            className={classes.img}
            src='/img/merki.png'
            alt='TAMPEREEN HYÖTYAUTO OY'
          />
          {/*           <Typography className={classes.typography} variant='h3'>
            <Box fontWeight='fontWeightBold'>TAMPEREEN HYÖTYAUTO OY</Box>
          </Typography> */}
        </Button>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabContainer}
          textColor='#fff'
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            style={{ textDecoration: 'none' }}
            className={classes.tab}
            label='Etusivu'
            component={Link}
            to='/'
          />
          <Tab
            style={{ textDecoration: 'none' }}
            className={classes.tab}
            label='Pakettiautot'
            component={Link}
            to='/autot'
          />
          <Tab
            style={{ textDecoration: 'none' }}
            className={classes.tab}
            label='Kuorma-autot'
            component={Link}
            to='/kuorma-autot'
          />
          <Tab
            style={{ textDecoration: 'none' }}
            className={classes.tab}
            label='Yhteystiedot'
            component={Link}
            to='/yhteystiedot'
          />
        </Tabs>
      </ToolBar>
      <Box className={classes.box}></Box>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <AppBar position='fixed'>
        <ToolBar className={classes.toolbar}>
          <Grid
            justify='space-between'
            container
            //spacing={24}
          >
            <Grid item>
              <div className={classes.paper}>
                <Button className={classes.button} component={Link} to='/'>
                  <img
                    className={classes.img}
                    src='/img/merki.png'
                    alt='TAMPEREEN HYÖTYAUTO OY'
                  />
                  {/* <Typography variant='body2'>TAMPEREEN HYÖTYAUTO OY</Typography> */}
                </Button>
              </div>
            </Grid>
            <Grid item>
              <SwipeableDrawer
                classes={{ paper: classes.drawer }}
                className={classes.test}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
              >
                <List disablePadding>
                  <ListItem
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to='/'
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography style={{ color: '#fff' }}>
                          Etusivu
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to='/autot'
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography style={{ color: '#fff' }}>
                          Pakettiautot
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to='/kuorma-autot'
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography style={{ color: '#fff' }}>
                          Kuorma-autot
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to='/yhteystiedot'
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography style={{ color: '#fff' }}>
                          Yhteystiedot
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </SwipeableDrawer>
              <IconButton
                className={classes.drawerIconContainer}
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                <Typography variant='caption' className={classes.valikko}>
                  Valikko
                </Typography>

                <MenuIcon className={classes.drawerIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </ToolBar>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );

  //absolute' 'fixed' 'relative' 'static' 'sticky'
  return (
    <React.Fragment>
      <AppBar position='static'>{matches ? drawer : tabs}</AppBar>
    </React.Fragment>
  );
}
