import React from 'react';
import Slider from 'react-slick';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EuroIcon from '@material-ui/icons/Euro';
import PhoneIcon from '@material-ui/icons/Phone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Laskuri from './Layouts/Laskuri';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { getOneCar } from '../store/actions/carsActions';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  margin: {
    marginTop: 50,
  },
  img: {
    //height: '450px',
  },
  button: {
    borderRadius: 20,
  },
  card: {
    marginTop: 20,
  },
  ringBtn: {
    color: '#fff',
    backgroundColor: '#3eb444',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1f6622',
    },
  },
  mainBox: {
    backgroundColor: '#1a2f48',
    color: '#fff',
    padding: '10px',
  },
  normalBox: {},
  marginBox: {
    padding: '10px',
    borderStyle: 'solid',
    borderWidth: '0.1px',
    marginTop: '20px',
  },
  largePics: {
    width: '100%',
  },
  liitlePics: {
    width: '100%',
  },
  typography: {
    fontFamily: '"Exo"',
    color: '#1a2f48',
  },
  marginBtn: {},
});

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'grey' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'grey' }}
      onClick={onClick}
    />
  );
}

class CarDetails extends React.Component {
  state = {
    car: [],
    nav1: null,
    nav2: null,
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  componentDidMount() {
    const car_id = this.props.match.params.car_id;
    this.props.getOneCar(car_id);
    if (this.state.car.length === 0) {
      this.props.getOneCar(car_id);
    }
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { classes, car } = this.props;
    if (!car) {
      return <CircularProgress></CircularProgress>;
    }
    const settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <Container>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <Laskuri />
          <DialogActions>
            <Button
              className={classes.ringBtn}
              variant='contained'
              color='primary'
              startIcon={<CancelIcon />}
              onClick={this.handleClose}
            >
              Kiinni
            </Button>
          </DialogActions>
        </Dialog>
        <Container className={classes.margin}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant='h4' className={classes.typography}>
                <Box fontWeight='fontWeightBold'>
                  {car.make.name} {car.model.name}
                </Box>
              </Typography>
              <Typography variant='body1'>{car.modelTypeName}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Slider
                {...settings}
                adaptiveHeight={true}
                asNavFor={this.state.nav2}
                ref={(slider) => (this.slider1 = slider)}
              >
                {car.images.map((image) => (
                  <div key={image.id}>
                    <img
                      className={classes.largePics}
                      src={image.large.url}
                      alt=''
                    />
                  </div>
                ))}
              </Slider>

              <Slider
                {...settings}
                asNavFor={this.state.nav1}
                ref={(slider) => (this.slider2 = slider)}
                slidesToShow={5}
                swipeToSlide={true}
                focusOnSelect={true}
                //arrows={true}
                //nextArrow='<SampleNextArrow />'
                //prevArrow='<SamplePrevArrow />'
              >
                {car.images.map((image) => (
                  <div key={image.id}>
                    <img
                      className={classes.liitlePics}
                      src={image.largeThumbnail.url}
                      alt=''
                    />
                  </div>
                ))}
              </Slider>

              <Grid className={classes.card} container justify='space-between'>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  startIcon={<EuroIcon />}
                  onClick={this.handleClickOpen}
                >
                  Laskuri
                </Button>
                <Button
                  className={classes.ringBtn}
                  variant='contained'
                  startIcon={<PhoneIcon />}
                  href='tel:+358503588213'
                >
                  Soita
                </Button>
              </Grid>
            </Grid>

            <Grid item sm={12} md={5}>
              <Grid className={classes.mainBox} container>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Hinta</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Rekisterinumero</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Vuosimalli</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Mittarilukema</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Polttoaine</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Vetotapa</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Vaihteisto</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Ajoneuvolaji</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Ovet</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Väri</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Omamassa</Box>
                  </Typography>
                  <Typography variant='subtitle2'>
                    <Box fontWeight='fontWeightBold'>Kokonaismassa</Box>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>
                    {car.price} € (ALV 24%)
                  </Typography>
                  <Typography variant='subtitle2'>
                    {car.registerNumber}
                  </Typography>
                  <Typography variant='subtitle2'>{car.year}</Typography>
                  <Typography variant='subtitle2'>
                    {car.kilometers} km
                  </Typography>
                  <Typography variant='subtitle2'>{car.fuelType.fi}</Typography>
                  <Typography variant='subtitle2'>
                    {car.driveType.fi}
                  </Typography>
                  <Typography variant='subtitle2'>{car.gearType.fi}</Typography>
                  <Typography variant='subtitle2'>
                    {car.vehicleType.fi}
                  </Typography>
                  <Typography variant='subtitle2'>
                    {car.doors ? car.doors : '-'}
                  </Typography>
                  <Typography variant='subtitle2'>{car.color.fi}</Typography>
                  <Typography variant='subtitle2'>
                    {car.curbWeight ? car.curbWeight : '-'}
                  </Typography>
                  <Typography variant='subtitle2'>
                    {car.grossWeight ? car.grossWeight : '-'} kg
                  </Typography>
                </Grid>
              </Grid>
              <Box className={classes.marginBox}>
                <Grid className={classes.normalBox} container>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                      <Box fontWeight='fontWeightBold'>
                        Suorituskyky ja taloudellisuus
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      <Box fontWeight='fontWeightBold'>Moottori</Box>
                    </Typography>
                    <Typography variant='subtitle2'>
                      <Box fontWeight='fontWeightBold'>Teho</Box>
                    </Typography>
                    <Typography variant='subtitle2'>
                      <Box fontWeight='fontWeightBold'>CO2</Box>
                    </Typography>
                    <Typography variant='subtitle2'>
                      <Box fontWeight='fontWeightBold'>Keskikulutus</Box>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      {car.engineSize} l
                    </Typography>
                    <Typography variant='subtitle2'>{car.power} kW</Typography>
                    <Typography variant='subtitle2'>
                      {car.co2Emission ? car.co2Emission : '-'} g/km
                    </Typography>
                    <Typography variant='subtitle2'>
                      {car.consumptionCombined ? car.consumptionCombined : '-'}{' '}
                      l/100km
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.marginBox}>
                <Grid className={classes.normalBox} container>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                      <Box fontWeight='fontWeightBold'>
                        Varusteet ja lisätiedot
                      </Box>
                    </Typography>
                    {car.description.split(/-/).map((descr) => (
                      <div key={descr.id}>
                        <Typography variant='subtitle2'>- {descr}</Typography>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  car: state.carsReducer.car,
  classes: [],
});

export default connect(mapStateToProps, { getOneCar })(
  withStyles(styles)(CarDetails)
);
