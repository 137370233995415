import axios from 'axios';
import * as actions from './index';

export const getMerces = () => async (dispatch) => {
  const res = await axios.get('/api/mersut');
  dispatch({ type: actions.GET_MERCES, merces: res.data });
};

export const getCars = () => async (dispatch) => {
  const res = await axios.get('/api/autot');
  dispatch({ type: actions.GET_CARS, cars: res.data });
};

export const getMachines = () => async (dispatch) => {
  const res = await axios.get(
    '/api/kuorma-autot'
  );
  dispatch({ type: actions.GET_MACHINES, machines: res.data });
};

export const getOneCar = (car_id) => async (dispatch) => {
  const res = await axios.get(
    `/api/autot/${car_id}`
  );
  dispatch({ type: actions.GET_ONE_CAR, car: res.data });
};

export const getOneMachine = (machine_id) => async (dispatch) => {
  const res = await axios.get(
    `/api/kuorma-autot/${machine_id}`
  );
  dispatch({ type: actions.GET_ONE_MACHINE, machine: res.data });
};
