import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EuroIcon from '@material-ui/icons/Euro';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Laskuri from './Layouts/Laskuri';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { getCars } from '../store/actions/carsActions';

const styles = (theme) => ({
  margin: {
    marginTop: 10,
  },
  img: {
    width: '100%',
  },
  button: {
    borderRadius: 20,
  },
  card: {
    marginTop: 20,
  },
  ringBtn: {
    color: '#fff',
    backgroundColor: '#3eb444',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1f6622',
    },
  },
  typography: {
    marginTop: 40,
    fontFamily: '"Exo"',
    color: '#1a2f48',
  },
  buttomBtn: {
    borderRadius: 20,
    marginTop: 40,
  },
});

class CarList extends React.Component {
  state = {
    cars: [],
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  componentDidMount() {
    this.props.getCars();
    if (this.state.cars.length === 0) {
      this.props.getCars();
    }
  }

  render() {
    const { classes, cars } = this.props;

    if (!cars) {
      return <CircularProgress></CircularProgress>;
    }

    return (
      <React.Fragment>
        <Grid item sm={12} md={12}>
          <Typography className={classes.typography} variant='h4'>
            <Box fontWeight='fontWeightBold'>PAKETTIAUTOT</Box>
          </Typography>
        </Grid>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <Laskuri />
          <DialogActions>
            <Button
              className={classes.ringBtn}
              variant='contained'
              color='primary'
              startIcon={<CancelIcon />}
              onClick={this.handleClose}
            >
              Kiinni
            </Button>
          </DialogActions>
        </Dialog>
        <Grid className={classes.margin} container justify='center' spacing={2}>
          {cars.map((car) => (
            <Grid key={car.id} item sm={12} md={3}>
              <Card className={classes.card}>
                <CardActionArea component={Link} to={`/autot/${car.id}`}>
                  <CardMedia
                    className={classes.img}
                    component='img'
                    image={car.images[0].medium.url}
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5'>
                      {car.make.name}
                    </Typography>
                    <Typography gutterBottom variant='h5'>
                      {car.model.name}
                    </Typography>
                    <Typography variant='body2'>
                      Vuosimalli: {car.year}
                    </Typography>
                    <Typography variant='body2'>
                      Mittarilukema: {car.kilometers}
                    </Typography>
                    <Typography variant='body2'>
                      Polttoaine: {car.fuelType.fi}
                    </Typography>
                    <Typography variant='h6' color='primary'>
                      Hinta: {car.price} €
                    </Typography>
                    <Typography variant='body2'>Hinta sis. ALV 24%</Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Grid container justify='space-between'>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      className={classes.button}
                      startIcon={<EuroIcon />}
                      onClick={this.handleClickOpen}
                    >
                      Laskuri
                    </Button>
                    <Button
                      className={classes.ringBtn}
                      variant='contained'
                      size='small'
                      startIcon={<PhoneIcon />}
                      href='tel:+358503588213'
                    >
                      Soita
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container justify='space-around'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              size='small'
              className={classes.buttomBtn}
              component={Link}
              to='/kuorma-autot/#'
            >
              Katso kaikki kuorma-autot
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cars: state.carsReducer.cars,
  classes: [],
});

export default connect(mapStateToProps, { getCars })(
  withStyles(styles)(CarList)
);
