import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

//import Header from './Components/Header';
import SlideNavbar from '../src/Components/SlideNavbar';
//import Navbar from '../src/Components/Navbar';
import Footer from '../src/Components/Footer';
//import NewSlide from '../src/Components/NewSlide';
//import Preload from '../src/Components/Preload';
import Main from '../src/Components/Main';
//import StaticHeader from '../src/Components/StaticHeader';
import NettiAuto from '../src/Components/NettiAuto';
import Tooltips from '../src/Components/Tooltips';
import KoneAuto from '../src/Components/KoneAuto';
import CarDetails from '../src/Components/CarDetails';
import KoneDetails from '../src/Components/KoneDetails';
import Yhteystiedot from '../src/Components/Yhteystiedot';
import getToken from './Components/Functions/Token';

class App extends Component {
  componentDidMount() {
    getToken();
  }
  render() {
    return (
      <div className='App'>
        <SlideNavbar />

        <Switch>
          <Route exact path='/' component={Main} />
          <Route exact path='/autot' component={NettiAuto} />
          <Route exact path='/autot/:car_id' component={CarDetails} />
          <Route exact path='/kuorma-autot' component={KoneAuto} />
          <Route
            exact
            path='/kuorma-autot/:machine_id'
            component={KoneDetails}
          />
          <Route exact path='/yhteystiedot' component={Yhteystiedot} />
        </Switch>

        <Tooltips />
        <Footer />
      </div>
    );
  }
}

export default App;
