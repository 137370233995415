import * as actions from '../actions';
const initialState = {
  merces: null,
  cars: null,
  machines: null,
  car: null,
  //machine: null,
};

export default (state = initialState, action) => {
  debugger;
  switch (action.type) {
    case actions.GET_MERCES:
      return { ...state, merces: action.merces };
    case actions.GET_CARS:
      return { ...state, cars: action.cars };
    case actions.GET_MACHINES:
      return { ...state, machines: action.machines };
    case actions.GET_ONE_CAR:
      return { ...state, car: action.car };

    case actions.GET_ONE_MACHINE:
      return { ...state, machine: action.machine };

    default:
      return state;
  }
};
