//export const Token =
//  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpZCI6ImE3Mjc5ZmQ2MzRlNTk4NWY0OTFjYjNkZGIzZTZhZTcyMjY5YWU3YTAiLCJqdGkiOiJhNzI3OWZkNjM0ZTU5ODVmNDkxY2IzZGRiM2U2YWU3MjI2OWFlN2EwIiwiaXNzIjoiIiwiYXVkIjoidGFtcGVyZWVuX2h5b3R5YXV0byIsInN1YiI6bnVsbCwiZXhwIjoxNjAwODAyMzk1LCJpYXQiOjE2MDA3MTU5OTUsInRva2VuX3R5cGUiOiJiZWFyZXIiLCJzY29wZSI6InJlYWQgd3JpdGUifQ.W9uPLlbjf8-4-j7JvhN9TDehOJLCJ_rUIJCNbOuY1vha8PyG_rGq_lWJWoKWlhFrUfnmerBq9ge_mMuoLB0wCrY8-CbYnaeOb8T_DSoiGDJhRXVvJv65ND0eDU1y8VtxmfjiXDqms4YxfKOSKKWSiPwHONqAruG1KJxE8TCStfMXjSd2UEEIFO4vGCv0zTdDrzkTpCIF4eRmUxa50yrZDdaYt8VtmZnJPpvbZNNS6nH5tXN8and8Ca60P4RO2XcmwGHnUVhYPyu7d52-2rhPm1HicxkwBqY7xuYf7frF2MiN8uAaTx3xSRoFYSOGLwNQ3eIOYa1siYMm3CKC-b_Z2A';

//let Token = '';

import { reactLocalStorage } from 'reactjs-localstorage';

function saveToken(token) {
  sessionStorage.setItem('tokenData', JSON.stringify(token));
}

function getTokenData() {  
  fetch(
    '/key.txt'
  )
  .then(response => response.body)
  .then(body => {
      const reader = body.getReader(); 
      reader.read().then(function processText({ done, value }) {   
      const tokenData = new TextDecoder().decode(value); //JSON without expires_on      
      saveToken(tokenData);      
      reactLocalStorage.set('token', tokenData);
      })
    })
    .catch((error) => console.log('error', error));
}

export default function getToken() {
  let tokenData = null;

  if (sessionStorage.tokenData) {
    tokenData = JSON.parse(sessionStorage.tokenData);
  } else {
    getTokenData();
  }
  // if (tokenData) {
  //   if (Date.now() >= Number(tokenData.expires_on)) {
  //     const newToken = getTokenData();
  //     saveToken(newToken);
  //   }
  // }
  // getToken();
  // return fetch;
  // //export default function fetchWithAuth() {
  // let tokenData = null;
  // if (sessionStorage.tokenData) {
  //   tokenData = JSON.parse(sessionStorage.tokenData);
  // } else {
  //   getToken();
  // }
  // if (tokenData) {
  //   if (Date.now() >= Number(tokenData.expires_on)) {
  //     try {
  //       const newToken = getToken();
  //       //const newToken = getToken();
  //       saveToken(newToken);
  //     } catch {
  //       //return window.location.replace(loginUrl);
  //     }
  //   }
  // }
  // //console.log('fetchWithAuth');
  // //const Token = JSON.parse(sessionStorage.tokenData).access_token;
  // //console.log(Token);
  // //return Token;
  // return fetch;
}
