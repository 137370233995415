import React, { Fragment, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
//import EuroIcon from '@material-ui/icons/Euro';
import ListAltIcon from '@material-ui/icons/ListAlt';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Yhteytta from './Layouts/Yhteytta';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  fabLeft: {
    margin: 0,
    top: 'auto',
    right: 'auto',
    bottom: 20,
    left: 20,
    position: 'fixed',
  },
  fabRight: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function BottomAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  //const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const litDialog = (
    <Dialog open={open} fullWidth={true}>
      <DialogActions>
        <Button
          className={classes.ringBtn}
          variant='contained'
          color='secondary'
          startIcon={<CancelIcon />}
          onClick={() => setOpen(false)}
        >
          Kiinni
        </Button>
      </DialogActions>
      <Yhteytta />
    </Dialog>
  );

  const bigButton = (
    <Fragment>
      {/* varmaan tarvi
      <Fab variant='extended' color='secondary' className={classes.fabLeft}>
        <EuroIcon className={classes.extendedIcon} />
        Santander Laskuri
      </Fab> */}
      <Fab
        variant='extended'
        color='primary'
        className={classes.fabRight}
        onClick={() => setOpen(true)}
      >
        <ListAltIcon className={classes.extendedIcon} />
        Ota yhteyttä
      </Fab>
    </Fragment>
  );

  const littleButton = (
    <React.Fragment>
      <Fragment>
        {/* <Fab variant='extended' color='secondary' className={classes.fabLeft}>
          <EuroIcon />
        </Fab> */}
        <Fab
          variant='extended'
          color='primary'
          className={classes.fabRight}
          onClick={() => setOpen(true)}
        >
          <ListAltIcon />
        </Fab>
      </Fragment>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div>{matches ? littleButton : bigButton}</div>
      {litDialog}
    </React.Fragment>
  );
}
