import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
//import AppBar from '@material-ui/core/AppBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navbar from './Navbar';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  appbar: {
    //backgroundColor: '#fff',
  },
  img: {
    //height: '500%',
    width: '100%',
    objectFit: 'fill',
  },
  bannerContainer: {
    //position: 'relative',
  },
  carousel: {
    //position: 'absolute',
    //top: 0,
  },
  navbar: {
    position: 'absolute',
    opacity: 0.6,
    zIndex: '100',
    //z-index:'10',
    //backgroundColor: '#fff',
    width: '100%',
    //paddingRight: 100,
    //margin: 0,
  },
  normal: {},
}));

export default function SlideNavbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const NavbarAdapt = (
    <React.Fragment>
      <div className={classes.normal}>
        <div className={classes.bannerContainer}>
          <div>
            <Navbar />
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const settings = {
    pauseOnHover: false,
    fade: true,
    speed: 3500,
    autoplaySpeed: 7000,
    autoplay: true,
  };

  const mainCarousel = (
    <React.Fragment>
      <div className={classes.normal}>
        <div className={classes.bannerContainer}>
          <div className={classes.navbar}>
            <Navbar />
          </div>
          <div className={classes.carousel}>
            <Slider {...settings} style={ {overflow: "hidden"}}>
              <div>
                <img className={classes.img} src='/img/slide1.png' alt='' />
              </div>
              <div>
                <img className={classes.img} src='/img/slide2.png' alt='' />
              </div>
              <div>
                <img className={classes.img} src='/img/slide3.png' alt='' />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className={classes.appbar} position='static'>
      {matches ? NavbarAdapt : mainCarousel}
    </div>
  );
}
