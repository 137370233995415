import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    //marginTop: '40px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  typography: {
    fontFamily: '"Exo"',
    color: '#1a2f48',
  },
  textField: {
    width: '100%',
  },
  textViesti: {
    //height: '500px',
  },
});

class Yhteystiedot extends React.Component {
  state = {
    name: '',
    email: '',
    subj: '',
    message: '',
    emailStatus: '',
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    const { name, email, subj, message } = this.state;

    e.preventDefault();
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      this.setState({
        emailStatus: xhr.responseText,
      });
    });
    xhr.open(
      'GET',
      //'http://localhost/index.php?sendto=' +
      'maillomak.php?sendto=' +
        email +
        '&subj=' +
        subj +
        '&name=' +
        name +
        '&message=' +
        message,
      true
    );
    xhr.send();
    this.setState({
      name: '',
      email: '',
      subj: '',
      message: '',
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <div className={classes.root}>
          <Typography className={classes.typography} variant='h4'>
            <Box fontWeight='fontWeightBold'>OTA YHTEYTTÄ</Box>
          </Typography>
          <Grid container>
            <form
              className={classes.root}
              onSubmit={this.handleSubmit}
              noValidate
              autoComplete='off'
            >
              <Grid item>
                <TextField
                  required
                  className={classes.textField}
                  //id='outlined-basic'
                  name='name'
                  label='Nimi'
                  variant='outlined'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid style={{ marginTop: 20 }} item sm={12}>
                <TextField
                  required
                  className={classes.textField}
                  //id='outlined-basic'
                  name='email'
                  label='Sähköposti'
                  variant='outlined'
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid style={{ marginTop: 20 }} item sm={12}>
                <TextField
                  className={classes.textField}
                  //id='outlined-basic'
                  name='subj'
                  label='Aihe'
                  variant='outlined'
                  value={this.state.subj}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item style={{ marginTop: 20 }} sm={12}>
                <TextField
                  required
                  multiline={true}
                  rows={12}
                  className={classes.textField}
                  //id='outlined-basic'
                  name='message'
                  label='Viesti'
                  variant='outlined'
                  value={this.state.message}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid style={{ marginTop: 20, marginBottom: 20 }} item sm={12}>
                <Button
                  startIcon={<MailOutlineIcon />}
                  variant='contained'
                  color='primary'
                  type='submit'
                  marginTop='10px'
                >
                  Lähetä
                </Button>
              </Grid>
            </form>
          </Grid>
        </div>
      </Container>
    );
  }
}
export default withStyles(styles)(Yhteystiedot);
