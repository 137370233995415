import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 40,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  typography: {
    fontFamily: '"Exo"',
    color: '#1a2f48',
  },
  merkkiTypography: {
    fontFamily: '"Exo"',
    color: '#1a2f48',
    fontSize: 48,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  textField: {
    width: '100%',
  },
  img: {
    width: '100%',
  },
  map: {
    width: '100%',
    height: 370,
  },
  chief: {
    //marginTop: 70,
  },
});

class Yhteystiedot extends React.Component {
  state = {
    name: '',
    email: '',
    subj: '',
    message: '',
    emailStatus: '',
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    // reset the fields
    const { name, email, subj, message } = this.state;

    e.preventDefault();
    //console.log(this.state);
    // const { name, email, message } = this.state;

    // // create a new XMLHttpRequest
    // var xhr = new XMLHttpRequest();
    const xhr = new XMLHttpRequest();
    //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.withCredentials = true;

    // // get a callback when the server responds
    xhr.addEventListener('load', () => {
      //   // update the response state and the step
      //console.log(xhr.responseText);
      this.setState({
        emailStatus: xhr.responseText,
      });
    });

    // // open the request with the verb and the url
    xhr.open(
      'GET',
      //'http://localhost/index.php?sendto=' +
      'maillomak.php?sendto=' +
        email +
        '&subj=' +
        subj +
        '&name=' +
        name +
        '&message=' +
        message,
      true
    );
    // // send the request
    xhr.send();
    this.setState({
      name: '',
      email: '',
      subj: '',
      message: '',
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Container>
          <div className={classes.root}>
            <Grid container>
              <Grid item sm={12} md={12}>
                <Typography className={classes.merkkiTypography} align='center'>
                  <Box fontWeight='fontWeightBold'>YHTEYSTIEDOT</Box>
                </Typography>
              </Grid>
              <Grid item sm={12} md={5} style={{ paddingTop: 40 }}>
                <Typography className={classes.typography} variant='h4'>
                  <Box fontWeight='fontWeightBold'>OTA YHTEYTTÄ</Box>
                </Typography>
                <Grid container sm={12}>
                  <form
                    className={classes.root}
                    onSubmit={this.handleSubmit}
                    noValidate
                    autoComplete='off'
                  >
                    <Grid item sm={12}>
                      <TextField
                        required
                        className={classes.textField}
                        //id='outlined-basic'
                        name='name'
                        label='Nimi'
                        variant='outlined'
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid style={{ marginTop: 20 }} item sm={12}>
                      <TextField
                        required
                        className={classes.textField}
                        //id='outlined-basic'
                        name='email'
                        label='Sähköposti'
                        variant='outlined'
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid style={{ marginTop: 20 }} item sm={12}>
                      <TextField
                        className={classes.textField}
                        //id='outlined-basic'
                        name='subj'
                        label='Aihe'
                        variant='outlined'
                        value={this.state.subj}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item style={{ marginTop: 20 }} sm={12}>
                      <TextField
                        required
                        multiline={true}
                        rows={15}
                        className={classes.textField}
                        //id='outlined-basic'
                        name='message'
                        label='Viesti'
                        variant='outlined'
                        value={this.state.message}
                        onChange={this.handleChange}
                      />
                    </Grid>

                    <Grid style={{ marginTop: 20 }} item sm={12}>
                      <Button
                        startIcon={<MailOutlineIcon />}
                        variant='contained'
                        color='primary'
                        type='submit'
                      >
                        Lähetä
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
              <Grid item md={2}></Grid>
              <Grid item sm={12} md={5} style={{ paddingTop: 40 }}>
                <Typography className={classes.typography} variant='h4'>
                  <Box fontWeight='fontWeightBold'>Tampereen Hyötyauto Oy</Box>
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  <Box style={{ marginTop: 20 }} fontWeight='fontWeightBold'>
                    Y-tunnus
                  </Box>
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  2080825-0
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  <Box style={{ marginTop: 10 }} fontWeight='fontWeightBold'>
                    Osoite
                  </Box>
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  Teollisuustie 8
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  33470 YLÖJÄRVI
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  <Box style={{ marginTop: 10 }} fontWeight='fontWeightBold'>
                    Kauppias
                  </Box>
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  Vesa Jalonen
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  <Box style={{ marginTop: 10 }} fontWeight='fontWeightBold'>
                    Puhelin
                  </Box>
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  050 3588 213
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  <Box style={{ marginTop: 10 }} fontWeight='fontWeightBold'>
                    Sähköposti
                  </Box>
                </Typography>
                <Typography className={classes.typography} variant='h6'>
                  tampereenhyotyauto@suomi24.fi
                </Typography>

                <Typography className={classes.typography} variant='h4'>
                  <Box style={{ marginTop: 20 }} fontWeight='fontWeightBold'>
                    Aukioloajat
                  </Box>
                </Typography>

                <Typography
                  style={{ marginTop: 10 }}
                  className={classes.typography}
                  variant='h6'
                >
                  Olemme avoinna:
                </Typography>
                <Typography
                  style={{ marginTop: 10 }}
                  className={classes.typography}
                  variant='h6'
                >
                  Ma-Pe 9-17
                </Typography>

                <Typography
                  className={classes.typography}
                  style={{ marginTop: 10 }}
                  variant='h6'
                >
                  muina aikoina sopimuksen mukaan.
                </Typography>

                <Typography
                  className={classes.typography}
                  style={{ marginTop: 10 }}
                  variant='h6'
                >
                  Varmista paikallaolo puhelimitse
                </Typography>
              </Grid>

              <Grid item sm={12} md={5} style={{ paddingTop: 40 }}>
                <iframe
                  className={classes.map}
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1900.477994578479!2d23.565401016163207!3d61.553890482491724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468f283ab9c603fd%3A0xc9de20f7db0d25b5!2sTeollisuustie%208%2C%2033470%20Yl%C3%B6j%C3%A4rvi!5e0!3m2!1sfi!2sfi!4v1602073070052!5m2!1sfi!2sfi'
                  title='map'
                  //width='600'
                  //height='408'
                  frameborder='0'
                  allowfullscreen=''
                  aria-hidden='false'
                  tabindex='0'
                ></iframe>
              </Grid>
              <Grid item md={2}></Grid>
              <Grid item sm={12} md={5} style={{ paddingTop: 40 }}>
                <Box className={classes.chief}>
                  <img
                    className={classes.img}
                    src='/img/vesa_kuva.png'
                    alt=''
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Yhteystiedot);
