import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  img: {
    width: '50%',
  },
  margin: {
    marginTop: '2rem',
  },
  txtLaskuri: {
    color: 'red',
  },
});

const MoneySlider = withStyles({
  root: {
    color: 'red',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const MonthSlider = withStyles({
  root: {
    color: 'red',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const KorkoSlider = withStyles({
  root: {
    color: 'red',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const SantLaskuri = withStyles(styles)(({ classes }) => {
  const [money, setMoney] = useState(20000);
  const [month, setMonth] = useState(48);
  const [korko, setKorko] = useState(3.5);
  let korkoSumma = ((money * (korko / 100)) / 12) * month;
  const [summa, setSumma] = useState(
    ((money / 1 + korkoSumma) / month + 0).toFixed(2)
  );

  const moneyChange = (e, newValue) => {
    setMoney(newValue);
    finalSumma(newValue, month, korko);
  };
  const moneyChangeInput = (e) => {
    setMoney(e.target.value);
    finalSumma(e.target.value, month, korko);
  };

  const monthChange = (e, newValue) => {
    setMonth(newValue);
    finalSumma(money, newValue, korko);
  };
  const monthChangeInput = (e) => {
    setMonth(e.target.value);
    finalSumma(money, e.target.value, korko);
  };

  const korkoChange = (e, newValue) => {
    setKorko(newValue);
    finalSumma(money, month, newValue);
  };
  const korkoChangeInput = (e) => {
    setKorko(e.target.value);
    finalSumma(money, month, e.target.value);
  };

  const finalSumma = (money, month, korko) => {
    let korkoSumma = ((money * (korko / 100)) / 12) * month;
    setSumma(((money / 1 + korkoSumma) / month + 0).toFixed(2));
  };

  return (
    <div className={classes.root}>
      <DialogTitle>
        <div className={classes.paper}>
          <img className={classes.img} src='../../img/Santander.png' alt='' />
          <Typography>
            <Box fontWeight='fontWeightBold'>
              Tullessasi autokaupoille, kysy meiltä myös kilpailukykyiset
              rahoitusvaihtoehdot.
            </Box>
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container justify='center' spacing={3}>
          <Grid item sm={12} md={6}>
            <div className={classes.root}>
              <form className={classes.root} noValidate autoComplete='off'>
                <Typography color='textSecondary'>Lainasumma</Typography>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={money}
                  onChange={moneyChangeInput}
                  endAdornment={
                    <InputAdornment disableTypography='true'>€</InputAdornment>
                  }
                />
              </form>
              <MoneySlider
                //valueLabelDisplay='auto'
                min={20000}
                max={60000}
                step={1000}
                defaultValue={20000}
                value={money}
                onChange={moneyChange}
              />
              <Grid container justify='space-between'>
                <Grid item>
                  <Typography variant='body2'>20 000,00 €</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>60 000,00 €</Typography>
                </Grid>
              </Grid>

              <form noValidate autoComplete='off'>
                <Typography color='textSecondary'>Korko</Typography>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={korko}
                  onChange={korkoChangeInput}
                  endAdornment={
                    <InputAdornment disableTypography='true'>%</InputAdornment>
                  }
                />
              </form>
              <KorkoSlider
                //valueLabelDisplay='auto'
                min={0}
                max={20}
                step={0.1}
                defaultValue={3.5}
                value={korko}
                onChange={korkoChange}
              />

              <Grid container justify='space-between'>
                <Grid item>
                  <Typography variant='body2'>0 %</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>20 %</Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.margin}
                justify='space-between'
              >
                <Grid item sm={12}>
                  <Typography color='textSecondary'>
                    Arvioidut lainan kustannukset
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography color='textSecondary'>Perustamismaksu</Typography>
                  <Typography color='textSecondary'>Tilinhoitomaksu</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography align='right'>350 €</Typography>
                  <Typography align='right'>9 € / kk</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.root}>
              <form className={classes.root} noValidate autoComplete='off'>
                <Typography color='textSecondary'>Laina-aika</Typography>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={month}
                  onChange={monthChangeInput}
                  endAdornment={
                    <InputAdornment disableTypography='true'>
                      kuukautta
                    </InputAdornment>
                  }
                />
              </form>

              <MonthSlider
                //valueLabelDisplay='auto'
                min={48}
                max={120}
                step={1}
                defaultValue={48}
                value={month}
                onChange={monthChange}
              />

              <Grid container justify='space-between'>
                <Grid item>
                  <Typography variant='body2'>48 kk</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>120 kk</Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.margin}>
                <Grid item sm={12}>
                  <Typography color='textSecondary'>
                    Arvioidu kuukausierä
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <Typography
                    align='center'
                    variant='h4'
                    className={classes.txtLaskuri}
                  >
                    {summa} € / kk
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
});

export default SantLaskuri;
