import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import EuroIcon from '@material-ui/icons/Euro';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Laskuri from './Layouts/Laskuri';
import CancelIcon from '@material-ui/icons/Cancel';
//import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import { getMerces } from '../store/actions/carsActions';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    margin: 10,
  },
  margin: {
    marginTop: '4rem',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  img: {
    //height: 320,
    width: '100%',
  },
  button: {
    borderRadius: 20,
  },
  ringBtn: {
    color: '#fff',
    backgroundColor: '#3eb444',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1f6622',
    },
  },
  buttomBtn: {
    borderRadius: 20,
    marginTop: 40,
  },
});

class CardsMain extends React.Component {
  state = {
    merces: [],
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  componentDidMount() {
    this.props.getMerces();
    if (this.state.merces.length === 0) {
      this.props.getMerces();
    }
    console.log(this.state);
  }

  render() {
    const { classes, merces } = this.props;

    if (!merces) {
      return <CircularProgress></CircularProgress>;
    }

    return (
      <React.Fragment>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <Laskuri />
          <DialogActions>
            <Button
              className={classes.ringBtn}
              variant='contained'
              color='primary'
              startIcon={<CancelIcon />}
              onClick={this.handleClose}
            >
              Kiinni
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.margin}>
          <div className={classes.root}>
            <Grid container justify='center'>
              {merces.map((car) => (
                <Grid key={car.id} item sm={12} md={3}>
                  <Card className={classes.root}>
                    <CardActionArea component={Link} to={`/autot/${car.id}`}>
                      <CardMedia
                        className={classes.img}
                        //width='10'
                        component='img'
                        image={car.images[0].medium.url}
                      />
                      <CardContent>
                        <Typography variant='h5'>{car.make.name}</Typography>
                        <Typography variant='h5'>{car.model.name}</Typography>
                        <Typography variant='body2'>
                          Vuosimalli: {car.year}
                        </Typography>
                        <Typography variant='body2'>
                          Mittarilukema: {car.kilometers}
                        </Typography>
                        <Typography variant='body2'>
                          Polttoaine: {car.fuelType.fi}
                        </Typography>
                        <Typography variant='h6' color='primary'>
                          Hinta: {car.price} €
                        </Typography>
                        <Typography variant='body2'>
                          Hinta sis. ALV 24%
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Grid container justify='space-between'>
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          className={classes.button}
                          startIcon={<EuroIcon />}
                          onClick={this.handleClickOpen}
                        >
                          Laskuri
                        </Button>
                        <Button
                          className={classes.ringBtn}
                          variant='contained'
                          size='small'
                          startIcon={<PhoneIcon />}
                          href='tel:+358503588213'
                        >
                          Soita
                        </Button>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Grid container justify='space-around'>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  className={classes.buttomBtn}
                  component={Link}
                  to='/autot'
                >
                  Katso kaikki pakettiautot
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  merces: state.carsReducer.merces,
  classes: [],
});

export default connect(mapStateToProps, { getMerces })(
  withStyles(styles)(CardsMain)
);
